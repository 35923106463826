const enDict = {
	Authorizate: 'Authorizate',
	Send: 'Send',
	Back: 'Back',
	'Customer on': 'Customer on',
	'End customer': 'End customer',
	Date: 'Date',
	From: 'From',
	'To an hour': 'To an hour',
	Total: 'Total',
	Remarks: 'Remarks',
	'Your remarks': 'Your remarks',
	Vacation: 'Vacation',
	Sick: 'Sick',
	Holiday: 'Holiday',
	ReportMesage: 'Please note that there is no report in the following days',
};

const heDict = {
	Authorizate: 'כניסה',
	Send: 'שלחו עדכון',
	Back: 'חזרה',
	'Customer on': 'לקוח',
	'End customer': 'לקוח סופי',
	Date: 'תאריך',
	From: 'משעה',
	'To an hour': 'עד שעה',
	Total: 'סה"כ',
	Remarks: 'הערות',
	'Your remarks': 'הערות שלך',
	Vacation: 'חופשה',
	Sick: 'מחלה',
	Holiday: 'לא עבדתי',
	ReportMesage: 'לתשומת ליבך אין דיווח בימים הבאים',
};
window.Dict = heDict;
export default heDict;
