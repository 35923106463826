//import { createApp } from 'vue/dist/vue.esm-browser.prod.js';
import { createApp } from 'vue/dist/vue.esm-browser.js';
import { html } from 'lit-html';

let sendingState = false;
window.emptyFields = {
	EndClient: '',
	ClientFor: '',
	Date: '',
	StartTime: '',
	EndTime: '',
	SummaryTime: '',
	Comment: '',
	Vacation: false,
	Sick: false,
	Holiday: false,
};

window.strictRequiredFields = {
	ClientFor: 1,
	Date: 1,
};

window.requiredFields = {
	StartTime: 1,
	EndTime: 1,
	SummaryTime: 1,
};

window.singleFillFields = {
	Vacation: 1,
	Sick: 1,
	Holiday: 1,
};

const app = createApp({
	data() {
		return {
			signed: +localStorage.getItem('signed'),
		};
	},
	template: html`
		<div class="content" :class="{w80: !signed}" dir="rtl">
			<login v-if="!signed" />
			<filling v-if="signed" />
		</div>
	`.strings[0],
});

app.component('login', {
	data() {
		return {
			Dict: window.Dict,
		};
	},
	methods: {
		signIn: function () {
			window.auth.signIn();
		},
	},
	template: html`<div class="login">
		<div class="content">
			<logo />
			<h3>
				עובד יקר, נא לעדכן שעות עבודה עד יום האחרון של החודש. בתחילת כל
				חודש יישלח אליך דו"ח שעות לוואטסאפ האישי.
			</h3>
			<main>
				* שימו לב! המייל אתו אתם נכנסים לאפליקציה צריך להיות זהה למייל
				הרשום בחברה.
				<br />
				* אם אתם נכנסים דרך אינקוגניטו תוודאו לא לחסום קוקיס.
			</main>
		</div>
		<footer-button :action="signIn" :text="Dict['Authorizate']" />

		<footer>
			במקרה של אי התחברות תלחצו על "רענון מסך" וכל תקלה אחרת תשלחו הודעה
			בווטסאפ: 051-537-8082
		</footer>
	</div>`.strings[0],
});
import downloadIcon from '../../assets/download-icon.svg';
app.component('filling', {
	data() {
		this.init();
		return {
			downloadIcon,

			state: 'filling',
			reportDates: [],
			Dict: window.Dict,

			clients: window.clientsData?.clients,

			Values: { ...window.emptyFields },

			block: false,
			loading: false,
			userName: window.userName || localStorage.getItem('userName'),
		};
	},
	methods: {
		init() {
			window.auth.on('changeClients', (EndClient, ClientFor, clients) => {
				this.Values.EndClient = window.emptyFields.EndClient =
					EndClient;
				this.Values.ClientFor = window.emptyFields.ClientFor =
					ClientFor;
				this.clients = clients;
			});
		},

		getBlock() {
			if (
				this.Values.Vacation ||
				this.Values.Sick ||
				this.Values.Holiday
			) {
				this.Values.StartTime = '';
				this.Values.EndTime = '';
				this.Values.SummaryTime = '';
				return true;
			} else return false;
		},
		checkFilling() {
			for (let value in window.strictRequiredFields) {
				if (!this.Values[value]) {
					if (value == 'Date') alert('Please insert date');
					if (value == 'ClientFor') alert('Please insert client');
					return false;
				}
			}

			if (
				this.clients &&
				!this.clients?.includes(this.Values.ClientFor)
			) {
				alert('Customer not on the list');
				return false;
			}
			for (let value in window.singleFillFields) {
				if (this.Values[value]) {
					return true;
				}
			}

			for (let value in window.requiredFields) {
				if (!this.Values[value]) {
					alert('Please insert working hours');
					return false;
				}
			}
			return true;
		},
		async send() {
			if (this.checkFilling() && window.signed && !this.loading) {
				this.loading = true;
				this.Values.UserName = window.userName;
				try {
					await auth.run('setWorkDay', [this.Values]);
					document.querySelector('#app').style.backgroundColor =
						'#c7edc7';
					setTimeout(() => {
						document.querySelector('#app').style.backgroundColor =
							'';
					}, 500);
					this.reportDates = await auth.run('getUnfilledDays');
				} catch (e) {
					console.log(e);
					alert('No access to the spreadsheet');
				}

				if (this.reportDates.length) {
					this.state = 'reports';
				}
				this.loading = false;
				this.block = false;
				this.Values = { ...window.emptyFields };
			}
		},
		async sendMeReport() {
			if (sendingState || !confirm('האם אתה בטוח שברצונך להגיש דוח?'))
				return;
			sendingState = true;

			try {
				await auth.run('sendMeReport');
				alert('מכתב נשלח');
			} catch (e) {
				console.error(e);
				alert('שגיאת שליחה');
			}
			sendingState = false;
		},
		back() {
			this.state = 'filling';
		},
		getReadonly() {
			return window.readonlyClients;
		},
		fillDate(date) {
			this.Values.Date = date;
			//this.$refs.Date.value = date;
			this.back();
		},
		getTimeDiff() {
			if (this.$refs.StartTime && this.$refs.EndTime) {
				let [t1, t2] = [
					this.$refs.StartTime.valueAsDate,
					this.$refs.EndTime.valueAsDate,
				];
				if (t1 && t2) {
					if (t2 - t1 < 0) {
						t2 = +t2 + 864e5;
					}
					let hours, minutes;

					if (t2 - t1 == 0) [hours, minutes] = [24, 0];
					else {
						let diff = new Date(t2 - t1);
						[hours, minutes] = [
							diff.getUTCHours(),
							diff.getUTCMinutes(),
						];
					}

					let ret = `${('' + hours).padStart(2, '0')}:${(
						'' + minutes
					).padStart(2, '0')}`;

					this.Values.SummaryTime = ret;
					return ret;
				}
			}
			this.Values.SummaryTime = '';
			return '--:--';
		},
	},
	template: html`<div class="filling">
		<div class="content" :class="{ loading:this.loading }">
			<logo />

			<div
				class="userName"
				style="display: flex; justify-content: center;"
			>
				<div style="text-align: center">{{userName}}</div>

				<img
					title="לשלוח דו״ח למייל"
					class="downloadIcon"
					:src="downloadIcon"
					@click="sendMeReport"
				/>
			</div>
			<div v-if="state == 'filling'">
				<div v-if="clients" class="input">
					<span>{{Dict['Customer on']}}: </span>

					<input v-model="Values.ClientFor" list="clientsList" />
					<datalist id="clientsList">
						<option v-for="client in clients" :value="client">
							{{ client }}
						</option>
					</datalist>
				</div>

				<div class="input">
					<span>{{Dict['Date']}}: </span>
					<input ref="Date" type="date" v-model="Values.Date" />
				</div>

				<div class="inputs2">
					<div>
						<span>{{Dict['From']}}: </span>
						<input
							ref="StartTime"
							type="time"
							:readonly="getBlock(block)"
							v-model="Values.StartTime"
						/>
					</div>
					<div>
						<span>{{Dict['To an hour']}}: </span>
						<input
							ref="EndTime"
							type="time"
							:readonly="getBlock(block)"
							v-model="Values.EndTime"
						/>
					</div>
				</div>

				<div class="input">
					<span>{{Dict['Total']}}: </span>
					<span
						class="totalTime"
						style="display: inline-block; color: #004e89;"
						>{{this.getTimeDiff(Values.StartTime,
						Values.EndTime)}}</span
					>
				</div>

				<div
					class="input"
					style="
						display: flex;
						flex-direction: column;
					"
				>
					<span
						style="
							margin-bottom: 25px;
							display: block;
						"
						>{{Dict['Remarks']}}:
					</span>
					<textarea
						style="
						flex: 1;
						width: inherit;
					"
						type="text"
						v-model="Values.Comment"
						:placeholder="Dict['Your remarks']"
					></textarea>
				</div>
				<div class="input">
					<input
						type="checkbox"
						@change="this.block=!this.block"
						v-model="Values.Vacation"
					/>
					{{Dict['Vacation']}}
				</div>
				<div class="input">
					<input
						type="checkbox"
						@change="this.block=!this.block"
						v-model="Values.Sick"
					/>
					{{Dict['Sick']}}
				</div>
				<div class="input">
					<input
						type="checkbox"
						@change="this.block=!this.block"
						v-model="Values.Holiday"
					/>
					{{Dict['Holiday']}}
				</div>
			</div>
			<div v-if="state == 'reports'">
				<div>{{Dict['ReportMesage']}}:</div>
				<div
					v-for="date in reportDates"
					class="clickDate"
					@click="this.fillDate(date)"
				>
					{{date}}
				</div>
			</div>
		</div>
		<footer-button
			v-if="state == 'filling'"
			:action="send"
			:text="Dict['Send']"
		/>
		<footer-button
			v-if="state == 'reports'"
			:action="back"
			:text="Dict['Back']"
		/>
	</div>`.strings[0],
});

import logo from '../../assets/logo.svg';
app.component('logo', {
	data() {
		return { logoJPG: logo };
	},
	template: html`<div class="logo">
		<img :src="logoJPG" />
	</div>`.strings[0],
});
app.component('footer-button', {
	props: ['action', 'text'],
	template: html`<div class="footer-button">
		<button @click="action">
			<span>{{text}}</span>
		</button>
	</div>`.strings[0],
});

export default app.mount('#app');
