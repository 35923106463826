import '../style.scss';

import './lib/sw-reg.js';

import Dict from './dictionary.js';
import auth from './lib/gauth.js';

import app from './lib/components.js';

if (__isServer)
	if (!new URLSearchParams(location.search).get('nohttps'))
		if (window.location.protocol != 'https:') {
			window.location.protocol = 'https:';
		}

window.app = app;
window.signed = false;

console.log('Hello4');

auth.once('signed', init);
auth.once('unsigned', () => {
	localStorage.setItem('signed', 0);
	window.signed = app.signed = false;
});

async function init() {
	localStorage.setItem('signed', 1);
	console.log('signed');
	let auth2 = gapi.auth2.getAuthInstance();
	let profile = auth2.currentUser.get().getBasicProfile();
	window.userName = profile.getName();
	window.userEmail = profile.getEmail();

	localStorage.setItem('userName', window.userName);
	localStorage.setItem('userEmail', window.userEmail);

	window.clientsData = await auth.run('getClients');

	if (window.clientsData.EndClient || window.clientsData.ClientFor) {
		window.readonlyClients = true;
		auth.emit(
			'changeClients',
			window.clientsData.EndClient,
			window.clientsData.ClientFor,
			window.clientsData.clients
		);
	}
	window.signed = app.signed = true;
}
