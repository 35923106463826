import EventEmitter from 'events';

import configLocal from '../configs/local.js';
import configServer from '../configs/server.js';
const config = __isServer ? configServer : configLocal;

class Auth extends EventEmitter {
	isSignedIn = false;
	constructor() {
		super();
		window.onload = () => {
			gapi.load('client:auth2', this.initClient.bind(this));

			let checkSigninInterval = setInterval(() => {
				console.log(123);
				let signinStatus = gapi.auth2
					.getAuthInstance()
					.isSignedIn.get();
				if (signinStatus) {
					if (!this.isSignedIn) this.updateSigninStatus(signinStatus);
					clearInterval(checkSigninInterval);
				}
			}, 5000);
		};
	}

	initClient() {
		gapi.client
			.init({
				apiKey: config.API_KEY,
				clientId: config.CLIENT_ID,
				discoveryDocs: config.DISCOVERY_DOCS,
				scope: config.SCOPES,
			})
			.then(
				() => {
					gapi.auth2
						.getAuthInstance()
						.isSignedIn.listen(this.updateSigninStatus.bind(this));
					this.updateSigninStatus(
						gapi.auth2.getAuthInstance().isSignedIn.get()
					);
				},
				e => {
					alert('Unable to connect');
					alert(JSON.stringify(e));
				}
			);
	}
	updateSigninStatus(isSignedIn) {
		this.isSignedIn = isSignedIn;
		if (isSignedIn) {
			this.emit('signed');
		} else {
			this.emit('unsigned');
		}
	}

	signIn() {
		gapi.auth2.getAuthInstance().signIn(); // Вход
	}

	signOut() {
		let auth2 = gapi.auth2.getAuthInstance();
		auth2.signOut().then(function () {
			auth2.disconnect();
			console.log('User signed out.');
			location.reload();
		});
	}

	/*async run(func, pars) {
		let resp = await gapi.client.script.scripts.run({
			scriptId: config.scriptId,
			resource: {
				function: func,
				parameters: pars,
			},
		});

		console.log(resp);
		return resp?.result?.response?.result;
	}*/
	async run(func, args) {
		let data = await new Promise((success, error) => {
			jQuery.ajax({
				crossDomain: true,
				url:
					config.api_url +
					'?data=' +
					encodeURIComponent(
						JSON.stringify({ func, args, email: userEmail })
					),
				method: 'GET',
				dataType: 'jsonp',
				success,
				error,
			});
		});
		if (data?.error) {
			if (data.error?.message == 'No access')
				alert('No access to the spreadsheet');
			console.log(data.error);
			throw data.error;
		}
		return data;
	}
}
let auth = new Auth();
window.auth = auth;
export default auth;
