if ('serviceWorker' in navigator) {
	window.addEventListener('load', async () => {
		navigator.serviceWorker.register('sw.js').catch(registrationError => {
			//console.log('SW registration failed: ', registrationError);
		});
		navigator.serviceWorker
			.getRegistration()
			.then(e => e.update().catch(() => {}))
			.catch(() => {});
	});
}
